import { configureStore } from "@reduxjs/toolkit";
import accountSlice from "src/redux/slice/account-slice";
import forgotPasswordSlice from "src/redux/slice/forgot-password-slice";
import settingsSlice from "./slice/settings-slice";
import searchSlice from "./slice/search-slice";
import tabSlice from "./slice/tab-slice";
import notificationSlice from "./slice/notification-slice";
const store = configureStore({
  reducer: {
    auth: accountSlice,
    forgotPassword: forgotPasswordSlice,
    settings: settingsSlice,
    search:searchSlice,
    tab:tabSlice,
    notifications:notificationSlice
  },
});
export default store;
