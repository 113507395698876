import { useState } from 'react';

import { useSelector } from 'react-redux/es/exports';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { RouterLink } from 'src/routes/components';

import { account } from 'src/_mock/account';
import { Skeleton } from '@mui/material';
import { checkPermission } from 'src/utils/check-permission';


const MENU_OPTIONS = [
  {
    label: 'Business',
    icon: 'eva:person-fill',
    path: '/account/business/settings/',
    havePermission: checkPermission('Business',"child"),
  },
  {
    label: 'Sales Agents',
    icon: 'eva:person-fill',
    path: '/sales-agent/',
    havePermission: checkPermission('Sales Agent',"child"),
  },
  {
    label: 'Account Settings',
    icon: 'eva:settings-2-fill',
    path: '/account/user/settings/',
    havePermission:checkPermission("Account Settings","child"),
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  // eslint-disable-next-line
  const { userInfo } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    handleClose();
  };
  return (
    <>
      {
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
         {!userInfo ? <Box>
          <Skeleton variant="circular"   sx={{
            width:36,
            height:36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}/>
         </Box> :
        <Avatar
          src={userInfo?.image?.url || ""}
          alt={account.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {userInfo? `${userInfo.first_name} ${userInfo.last_name}`.charAt(0).toUpperCase() : account.displayName}
        </Avatar>}
      </IconButton>}

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
          {userInfo? `${userInfo.first_name} ${userInfo.last_name}` : account.displayName.charAt(0).toUpperCase()}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userInfo? userInfo.email : account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {MENU_OPTIONS.map((option) => (
          option.havePermission &&
          <MenuItem key={option.label} onClick={handleClose} component={RouterLink} href={option.path}>
            {option.label}
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleLogout}
          sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
