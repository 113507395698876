import axios from "axios";

const axios_api = axios.create({
    baseURL : process.env.REACT_APP_API_BASE_URL,  // our API base URL
});

// Add a request interceptor
axios_api.interceptors.request.use(
    (config) => {
      // Check if access token is available and not expired
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      config.headers["Time-Zone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.headers["X-Channel"] = "admin";
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);
  
// Add a response interceptor
axios_api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
  
      // Check if the error is due to an expired access token
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        // Obtain a new access token using the refresh token
        const refreshToken = localStorage.getItem('RefreshToken');
  
        // Make a request to your token refresh endpoint
        const newAccessToken = await axios_api.post('/account/token/', {
          RefreshToken: refreshToken,
        });

        if ( newAccessToken.data.result === "failure" ) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('RefreshToken');
          window.location.reload();
        }
  
        // Update the stored access token
        localStorage.setItem('accessToken', newAccessToken.data.access_token);
  
        // Retry the original request with the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken.data.access_token}`;
        return axios(originalRequest);
      }
  
      return Promise.reject(error);
    }
);

export default axios_api;