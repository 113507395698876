import SvgColor from "src/components/svg-color";
import { checkPermission } from "src/utils/check-permission";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);



const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: icon("ic_analytics"),
    havePermission:true,
  },
  {
    title: "businesses management",
    path: "/business-management/",
    icon: icon("ic_business"),
    havePermission:checkPermission("Business Management"),
  },
  {
    title: "customer Management",
    path: "/customer-management/",
    icon: icon("ic_customer"),
    havePermission:checkPermission("Customer Management"),
  },
  {
    title: "dealer enquiries",
    path: "/dealer-enquiry/",
    navSlug:"nav_dealer_enquiry",
    icon: icon("dealer_enquiry"),
    havePermission:checkPermission("Enquiries"),
  },
  {
    title: "enquiries",
    path: "/contact-enquiry/",
    navSlug:"nav_contact_enquiry",
    icon: icon("ic_contact"),
    havePermission:checkPermission("Enquiries"),
  },
  {
    title: "vehicle catalogue",
    path: "vehicle",
    icon: icon("ic_sedan"),
    havePermission:checkPermission("Vehicle Catalogue"),
    children: [
      { title: "Horsepower", path: "/vehicle/horsepower/",havePermission:true },
      { title: "Engine Capacities", path: "/vehicle/engine-capacity/",havePermission:true },
      { title: "Number Of Cylinders", path: "/vehicle/no-of-cylinder/",havePermission:true },
      { title: "Warranties", path: "/vehicle/warranty/",havePermission:true },
      { title: "Service Contracts", path: "/vehicle/service-contract/",havePermission:true },
      { title: "Doors", path: "/vehicle/doors/",havePermission:true },
      { title: "Seating Capacities", path: "/vehicle/seats/",havePermission:true },
      { title: "Vehicle Condition", path: "/vehicle/condition/",havePermission:true },
      { title: "Brands", path: "/vehicle/brand/",havePermission:true },
      { title: "Brand Models", path: "/vehicle/brand-model/",havePermission:true },
      { title: "Body Types", path: "/vehicle/body-type/",havePermission:true },
      { title: "Model Trims", path: "/vehicle/model-trim/",havePermission:true },
      { title: "Transmission Types", path: "/vehicle/transmission-type/",havePermission:true },
      { title: "Regional Specs", path: "/vehicle/regional-specs/",havePermission:true },
      { title: "Fuel Types", path: "/vehicle/fuel-type/",havePermission:true },
      { title: "Color Codes", path: "/vehicle/automarket-color/",havePermission:true },
      { title: "Technical Features", path: "/vehicle/technical-feature/",havePermission:true },
      // { title: "Badge", path: "/vehicle/badge/" },
      { title: "Drivetrains", path: "/vehicle/drivetrain/",havePermission:true },
      { title: "Target Markets", path: "/vehicle/target-market/",havePermission:true },
      { title: "Steering Sides", path: "/vehicle/steering-side/",havePermission:true },
      {title: "Single Owners", path: "/vehicle/single-owner/",havePermission:true },
      { title: "Service Histories", path: "/vehicle/service-history/",havePermission:true },
      // { title: "Vehicles", path: "/vehicle/" },
      // { title: "Business", path: "/vehicle/business/" },
      // { title: "Automarket Reviews", path: "/vehicle/review/" },
      // { title: "Customer Reviews", path: "/vehicle/customer-review/" },
    
      { title: "Reports", path: "/vehicle/reports/",havePermission:true },
    ],
  },
  {
    title: "Ad listing",
    path: "listing",
    icon: icon("ic-list"),
    havePermission:checkPermission("Vehicle Listings"),
    children: [
      { title: "Business's Ads", 
        path: "/ad-listing/business/",
        havePermission:checkPermission("Business Ads","child") },
      { title: "Individual's Ads", 
        path: "/ad-listing/customer/",
        havePermission:checkPermission("Individual Ads","child") },
    ],
  },
  {
    title: "Packages & Add Ons",
    path: "add-ons",
    icon: icon("ic_package"),
    havePermission:checkPermission("Packages & Add-ons"),
    children: [
      { title: "Packages", 
        path: "/add-ons/packages/", 
        icon: icon("ic_star"),
        havePermission:checkPermission("Business Packages","child"),
      },
      {
        title: "Business Add Ons",
        path: "/add-ons/business/",
        icon: icon("ic_star"),
        havePermission:checkPermission("business_add_ons","child")
      },
      {
        title: "Customer Add Ons",
        path: "/add-ons/customer/",
        icon: icon("ic_star"),
        havePermission:checkPermission("individual_add_ons","child")
      },
    ],
  },
  {
    title: "Editorials",
    path: "listing",
    icon: icon("ic-editorial"),
    havePermission:checkPermission("Editorials"),
    children: [
      { title: "AutoMarket Reviews", 
        path: "/vehicle/review/",
        havePermission:checkPermission("editorial_automarket_car_reviews","child") },
      { title: "Customer Reviews", 
        path: "/vehicle/customer-review/",
        havePermission:checkPermission("editorials_customer_car_reviews","child") 
      },
      { title: "Guides", 
        path: "/settings/guides-and-faq/",
        havePermission:checkPermission("editorials_guides","child")
      },
      { title: "FAQ", 
        path: "/settings/faq/",
        havePermission:checkPermission("editorials_faqs","child")
      },
    ],
  },
  {
    title: "settings",
    path: "settings",
    icon: icon("ic_settings"),
    havePermission:checkPermission("Settings"),
    children: [
      {
        title: "Page Templates",
        path: "/settings/page-template/",
        icon: icon("ic_star"),
        havePermission:checkPermission("settings_page_template","child")
      },
      {
        title: "Email Templates",
        path: "/settings/email-template/",
        icon: icon("ic_star"),
        havePermission:checkPermission("settings_email_template","child")
      },
      {
        title: "Main Sliders",
        path: "/settings/main-slider/",
        icon: icon("ic_star"),
        havePermission:checkPermission("settings_homepage_banner","child")
      },
      {
        title: "Advertisements",
        path: "/advertisement/",
        icon: icon("ic_star"),
        havePermission:checkPermission("settings_adverts","child")
      },
      {
        title: "News",
        path: "/settings/news/",
        icon: icon("ic_star"),
        havePermission:checkPermission("settings_news","child")
      },
      {
        title: "Services",
        path: "/settings/automarket-service/",
        icon: icon("ic_star"),
        havePermission:checkPermission("settings_homepage_services","child")
      },
      {
        title: "Features",
        path: "/settings/automarket-feature/",
        icon: icon("ic_star"),
        havePermission:checkPermission("settings_homepage_features","child")
      },
    ],
  },
  // {
  //   title: "chats",
  //   path: "chat",
  //   icon: icon("ic_chat"),
  //   havePermission:true
  // },
  {
    title: "configurations",
    path: "configurations",
    icon: icon("ic_config"),
    havePermission:checkPermission("Configuration","child")
  },
];

// if(!permissions) window.location.reload()
export default navConfig;
