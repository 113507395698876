import { Box, TextField, Typography } from "@mui/material";
import { memo } from "react";
const specialCharacters = [
  "`",
  "~",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "_",
  "-",
  "+",
  "=",
  "{",
  "[",
  "}",
  "]",
  "|",
  "\\",
  ":",
  ";",
  "'",
  '"',
  "<",
  ",",
  ">",
  ".",
  "?",
  "/",
];
const numberSpecialCharacters = ["E", "e", "+", "-"];
function titleCase(string) {
  return string
    .split("_")
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(" ");
}

const formatLabel = (name) => {
  return name
    .replace("_id", "")
    .split("_")
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(" ");
};

function CustomTextInputComponent({
  type = "text",
  name = "",
  label = null,
  formik = null,
  onChange = null,
  size = "small",
  required = false,
  inputRef = null,
  min = "",
  rows = 1,
  disabled = false,
  placeholder = "",
  onKeyDown = () => {},
  onKeyUp = () => {},
  shrinkLabel,
  cursor = "auto",
  allowSpecialCharacters = true,
  disableValue = false,
}) {
  label = !label ? titleCase(formatLabel(name)) : titleCase(label);
  const handleChange = onChange ? onChange : formik.handleChange;

  return (
    <Box>
      {/* to hide auto filling name, password fields */}
      {(["password", "email", "username"].includes(name) ||
        ["password", "email", "username"].includes(label)) && (
        <input
          type="text"
          autoComplete="new-password"
          style={{ display: "none" }}
        />
      )}

      <TextField
        sx={{ input: { cursor: cursor }, cursor: cursor }}
        type={type}
        variant="outlined"
        placeholder={placeholder}
        size={size}
        multiline={rows > 1}
        rows={rows}
        label={`${label}${required ? " *" : ""}`}
        disabled={disabled}
        name={name}
        inputRef={inputRef}
        fullWidth
        value={
          disableValue
            ? ""
            : formik.values[name] !== undefined && formik.values[name] !== null
            ? formik.values[name]
            : type === "color"
            ? ""
            : ""
        }
        onChange={handleChange ? handleChange : formik.handleChange}
        onKeyDown={(evt) => {
          if (type === "number") {
            if (numberSpecialCharacters.includes(evt.key)) {
              evt.preventDefault();
            }
          }
          if (!allowSpecialCharacters) {
            if (specialCharacters.includes(evt.key)) {
              evt.preventDefault();
            }
          }
        }}
        onPaste={(evt) => {
          const string = evt.clipboardData.getData("text");
          if (type === "number") {
            if (
              string
                .split("")
                .some((char) => numberSpecialCharacters.includes(char))
            ) {
              evt.preventDefault();
            }
          }
          if (!allowSpecialCharacters) {
            if (
              string.split("").some((char) => specialCharacters.includes(char))
            ) {
              evt.preventDefault();
            }
          }
        }}
        onBlur={formik.handleBlur}
        onKeyUp={onKeyUp}
        onWheel={(e) => e.target.blur()}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        InputProps={{ inputProps: min ? { min: min } : {} }}
        InputLabelProps={shrinkLabel ? { shrink: shrinkLabel } : {}}
        autoComplete={
          ["password", "email", "username"].includes(label)
            ? "off"
            : "new-password"
        }
      />
      {formik.touched[name] && formik.errors[name] && (
        <Typography variant="caption" color={"error"} sx={{ marginTop: 0 }}>
          {formik.errors[name]}
        </Typography>
      )}
    </Box>
  );
}

export const CustomTextInput = memo(CustomTextInputComponent);
