import { Helmet } from 'react-helmet-async';

import { ForgotPasswordOtp } from 'src/sections/forgot-password-otp';

// ----------------------------------------------------------------------

export default function ForgotPasswordOtpPage() {
  return (
    <>
      <Helmet>
        <title> Forgot Password OTP | Automarket </title>
      </Helmet>

      <ForgotPasswordOtp />
    </>
  );
}
