import { useEffect, useState } from "react";
import {
  Badge,
  Typography,
  IconButton,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import { fToNow } from "src/utils/format-time";

import Iconify from "src/components/iconify";
import axios_api from "src/api/axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleNotifications } from "src/redux/slice/settings-slice";

function formatTimeAgo(updated_at) {
  const now = new Date();
  const updatedAt = new Date(updated_at);
  const diff = now - updatedAt;
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  if (diffHours < 1) {
    return "Less than an hour ago";
  } else if (diffHours === 1) {
    return "1 hour ago";
  } else if (diffHours < 4) {
    return `${diffHours} hours ago`;
  } else {
    return "before that";
  }
}

const CHANNEL = "admin";
const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const ACCESS_TOKEN = localStorage.getItem("accessToken");
const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

export default function NotificationsPopover() {
  let heartBeatSocket = null;
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(null);
  const [totalUnRead, setTotalUnRead] = useState(null);
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    try {
      const { data } = await axios_api.get("/account/notification/listing/");
      let serverNotifications = data["records"];
      serverNotifications = serverNotifications.map((notification) => {
        const formattedTime = formatTimeAgo(notification.updated_at);
        return {
          action: notification.action,
          action_id: notification.action_id,
          id: notification.id,
          is_read: notification.is_read,
          message: notification.message,
          source: notification.source,
          timeString: formattedTime,
          updated_at: notification.updated_at,
        };
      });
      
      setNotifications(serverNotifications);
      setTotalUnRead(data["unread_tcount"]);
    } catch (error) {
      // console.logerror);
    }
  };

  // const handleOpen = (event) => {
  //   setOpen(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setOpen(null);
  // };

  // const closeNotification = () => {
  //   setOpen(false);
  //   dispatch(toggleNotifications());
  // };

  const connectHeartBeatSocket = () => {
    if (heartBeatSocket) heartBeatSocket.close();
    heartBeatSocket = new WebSocket(
      `${SOCKET_BASE_URL}/heart-beat/${ACCESS_TOKEN}/?x-channel=${CHANNEL}&time-zone=${TIMEZONE}&token=${ACCESS_TOKEN}`
    );
    heartBeatSocket.onmessage = function (event) {
      const data = JSON.parse(event.data);
 
      
      setTotalUnRead(data["notification_unread_tcount"]);
      // if(data["notification_unread_tcount"] !== totalUnRead){
      //   getNotifications();
      // }
      heartBeatSocket.send("heartbeat");
    };
  };

  useEffect(()=>{
    if (!heartBeatSocket) connectHeartBeatSocket();

    return ()=>{
      if (heartBeatSocket) {
        heartBeatSocket.close();
      }
    };
    // eslint-disable-next-line
  },[]);
  
  return (
    <>
      <IconButton color={open ? "primary" : "default"} onClick={()=>{
        navigate("/notifications");
      }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify width={24} icon="solar:bell-bing-bold-duotone" />
        </Badge>
      </IconButton>

      {/* <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />
        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          {
            notifications.slice(0,8).map(notification=>(
              <NotificationItem
                key={notification.id}
                notification={notification}
                closeNotification={closeNotification}
                isRead={!notification.is_read}
              />
            ))
          }
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={() => {
              navigate("/notifications")
              handleClose();
            }}
          >
            View All
          </Button>
        </Box>
      </Popover> */}
    </>
  );
}

function NotificationItem({ notification, closeNotification,isRead }) {
  const navigator = useNavigate();

  const handleNavigation = () => {
    const { action, action_id, created_by } = notification;
    let url = null;
    switch (action) {
      case "vehicle":
        url = created_by === "business" ? `/ad-listing/business/details/${action_id}/`:`/ad-listing/business/details/${action_id}/`;
        break;
      default:
        url = null;
    }

    navigator(url);
    closeNotification();
  };

  return (
    <ListItemButton
      sx={{
        // py: 1.5,
        // px: 2.5,
        mt: "1px",
        ...(isRead && {
          bgcolor: "action.selected",
        }),
        backgroundColor: "",
      }}
      onClick={handleNavigation}
      selected={isRead}
    >
      <ListItemAvatar>
        <img
          src="/assets/icons/ic_notification_chat.svg"
          alt="message_icon"
        ></img>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="body2">
          {notification.message.slice(0, 40)+"...."}
        </Typography>}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.updated_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
