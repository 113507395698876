// ExampleComponent.jsx
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { RouterLink } from './routes/components';

const FiveHoundedErrorPage = () => {
  return (<Stack sx={{ display: "flex", height: "100vh" }} justifyContent="center" alignItems="center">
    <Typography variant="h1" fontSize={"10em"} fontWeight={"bold"} color={"primary"}>500</Typography>
    <Typography variant="h3" >Sorry, unexpected error</Typography>
    <Typography variant="subtitle1">We are working on fixing the problem,Be back soon </Typography>
    <Button
      href="/"
      size="large"
      variant="contained"
      component={RouterLink}
      sx={{mt:2}}
    >
      Go to Home
    </Button>
  </Stack>)
};

export default FiveHoundedErrorPage;
