import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const accountLogin = createAsyncThunk(
    '/account/signin/',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
            const headers = {
                'Content-Type': 'application/json',
                'X-Channel': process.env.REACT_APP_X_CHANNEL,
                'Time-Zone': timeZone,
            }
            const config = {
                headers: headers,
                //withCredentials: true,
            }
            const { data } = await axios.post(
                `${ API_BASE_URL }/account/signin/`, 
                {email, password},
                config
            )
            if(data?.result === "error" || data?.result === "failure"){
                return rejectWithValue( data );
            }
           
            //changeable
            // store user's token in local storage
            if (data.status === 'success' && data.token.access_token){
                localStorage.setItem( 'accessToken', data.token.access_token );
                localStorage.setItem("permissions", JSON.stringify(data.permissions));
                window.location.reload();
                //return data;
            } else {
                return rejectWithValue( data.errors );
            }

            return data
        } catch( error ) {
            if (error.response && error.response.data.message) {
                return rejectWithValue( {"instance": error.response.data.message} );
            } else {
                return rejectWithValue( {"instance": error.message} )
            }
        }
    }
)