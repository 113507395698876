import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTab:0
};

const tabSlice = createSlice({
  name: "tab_slice",
  initialState,
  reducers: {
    updateTab: (state, action) => {
      state.currentTab = action.payload;
    }
  },
});

export const { updateTab } = tabSlice.actions;
export default tabSlice.reducer;
