import "src/global.css";

import { useScrollToTop } from "src/hooks/use-scroll-to-top";
import { Provider } from "react-redux";

import store from "src/redux/store";
import Router from "src/routes/sections";
import ThemeProvider from "src/theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ErrorBoundary } from "react-error-boundary";
import FiveHoundedErrorPage from "./500-error-page";
import { GoogleMapsProvider } from "./google-map-context";
import { useEffect } from "react";

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <ErrorBoundary fallback={<FiveHoundedErrorPage />}>
      <Provider store={store}>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <GoogleMapsProvider>
              <Router />
            </GoogleMapsProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
      {/* <FiveHoundedErrorPage/> */}
    </ErrorBoundary>
  );

}
