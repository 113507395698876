import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";
import { bgGradient } from "src/theme/css";
import Logo from "src/components/logo";
import { RouterLink } from "src/routes/components";
import axios_api from "src/api/axios";
import { CustomTextInput } from "src/components/custom-text-input/custom-test-input";
import { useIsAuthenticated } from "src/routes/hooks/use-is-authenticated";
import { useNavigate } from "react-router-dom";
import { prompt } from "src/utils/prompt";
import { getMailRegex } from "src/utils/get-mail-regex";
import { Alert } from "@mui/material";

export default function ForgotPassword() {
  const theme = useTheme();
  const navigate = useNavigate();
  useIsAuthenticated();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  //function to run timer for one minute

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().trim().required("Email is required")
    .test("emailinvalid","Email is invalid",value=>{
      return getMailRegex().test(value)
   }),
    }),
    onSubmit: async (values) => {
      try {
        setError("");
        setLoading(true);
        const { data } = await axios_api.post(
          `account/recover-password-token/`,
          values
        );
        setLoading(false);
        if (data["result"] === "success") {
          prompt.success(data["message"]);
          console.log(data,data["token"]);
          
          localStorage.setItem("forgot-password-token", data["token"]);
          localStorage.setItem("password-reset-counter",600);
          setTimeout(() => {
            prompt.close();
            navigate(`/forgot-password/${formik.values["email"].trim()}/otp`);
          }, 2000);
        } else {
          setError(data["errors"]["email"]);
        }
      } catch (error) {
        setLoading(false);
        setError("Something went wrong. Please try again later");
      }
    },
  });

 

  const renderForm = (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack spacing={3} mb={5}>
        <CustomTextInput name="email" size="large" formik={formik} />
      </Stack>
    
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 3 }}
      >
        <Link
            component={RouterLink}
            variant="subtitle2"
            underline="hover"
            to="/login"
          >
            Back to login
          </Link>
      </Stack>
    
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        loading={loading}
      >
        Send OTP
      </LoadingButton>
    </form>
  );

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_4.jpg",
        }),
        height:"100vh"
      }}
    >
      <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />

      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
            <img src="/assets/automarket-logo.png" alt="logo" height={30} />
          </Box>
          <Typography variant="h4" mb={5}>
            Forgot password
            {error && <Alert severity="error" sx={{mt:3}}>{error}</Alert>}
          </Typography>
          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
}
