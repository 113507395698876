export const account = {
  displayName: 'Jaydon Frankie',
  email: 'demo@minimals.cc',
  photoURL: '/assets/images/avatars/avatar_25.jpg',
  role: 'admin',

  constructor({ displayName, email, photoURL }) {
    this.displayName = displayName;
    this.email = email;
    this.photoURL = photoURL;
    this.role = 'admin';
  }
};
