import { createSlice } from '@reduxjs/toolkit'
import axios_api from 'src/api/axios';
import { accountLogin } from 'src/features/account/account-actions'
import { prompt } from 'src/utils/prompt';

let accessToken = localStorage.getItem('accessToken', null) || 'undefined';
accessToken = accessToken === 'undefined' ? null : accessToken;
let userInfo = {};
async function fetchUserInfo() {
  try {
    
    const { data } = await axios_api.get("/account/profile/");
    userInfo = data["record"];
  } catch (error) {
    prompt.error("Error fetching user details");
  }
  
}

if(!Object.keys(userInfo).length === 0 && accessToken) fetchUserInfo();


const initialState = {
  loading: false,
  userInfo: userInfo, // for user object
  accessToken: accessToken, // for storing the access token
  RefreshToken: null, // for storing the refresh token
  error: null,
  success: false, // for monitoring the registration process.
};

const accountSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addAccount: (state, action) => {
      state.userInfo = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.RefreshToken = action.payload.RefreshToken;
    }
  },
  extraReducers: {
    // Account Login
    // API call pending: accountLogin
    [accountLogin.pending]: ( state ) => {
      state.loading = true;
      state.error = null;
    },
    // API call success: accountLogin
    [accountLogin.fulfilled]: ( state, action ) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    // API call failure: accountLogin
    [accountLogin.rejected]: ( state, action ) => {
      state.loading = false;
      //state.error = action.payload;
      state.success = false;
    },
  },
});

export const {
  addAccount
} = accountSlice.actions;

export default accountSlice.reducer;