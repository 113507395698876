import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuOpen: false,
  notification: false,
  backdropVisible: false,
  activeMenu:""
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleMenu: (state, action) => {
      state.menuOpen = !state.menuOpen;
    },
    toggleNotifications: (state) => {
      state.notification = !state.notification;
    },
    toggleBackdropVisibility: (state,action) => {
      state.backdropVisible = action.payload;
    },
    setActiveMenu:(state,action)=>{
      // console.logaction.payload);
      state.activeMenu = action.payload;
    }
  },
});

export const { toggleNotifications, toggleMenu,toggleBackdropVisibility,setActiveMenu } = settingsSlice.actions;
export default settingsSlice.reducer;
