import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import { useResponsive } from "src/hooks/use-responsive";

import { bgBlur } from "src/theme/css";

import Iconify from "src/components/iconify";

import Searchbar from "./common/searchbar";
import { NAV, HEADER } from "./config-layout";
import AccountPopover from "./common/account-popover";
import ChatButton from "./common/chat-button";
import NotificationsPopover from "./common/notifications-popover";
import axios_api from "src/api/axios";
import { useDispatch, useSelector } from "react-redux";
import { addAccount } from "src/redux/slice/account-slice";
import { Backdrop, CircularProgress } from "@mui/material";
import { prompt } from "src/utils/prompt";

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const lgUp = useResponsive("up", "lg");
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const backdropVisible = useSelector(
    (state) => state.settings.backdropVisible
  );

  //set user info
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken && Object.keys(userInfo).length ===0) {
    axios_api
      .get("/account/profile/")
      .then(({ data: { record } }) => {
        dispatch(
          addAccount({
            user: record,
            accessToken: accessToken,
          })
        );
      })
      .catch((error) => {
        prompt.error("Something went wrong");
      });
  }
  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Searchbar />
      <Box sx={{ flexGrow: 1 }} />
      <Stack direction="row" alignItems="center" spacing={1}>
        {/* <ChatButton /> */}
        {!window.location.pathname.includes("notifications") && <NotificationsPopover />}
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <>
      <AppBar
        sx={{
          boxShadow: "none",
          height: HEADER.H_MOBILE,
          zIndex: 999,
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          transition: theme.transitions.create(["height"], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(lgUp && {
            width: `calc(100% - ${NAV.WIDTH + 1}px)`,
            height: HEADER.H_DESKTOP,
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
          }}
        >
          {renderContent}
        </Toolbar>
      </AppBar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
