import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

function titleCase(string) {
  return string
    .split("_")
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(" ");
}

export function CustomTextInputPassword({
  type = "text",
  name = "",
  label = null,
  formik = null,
  onChange = null,
  size = "small",
  errorLabel = true,
  required = true,
}) {
  const handleChange = onChange ? onChange : formik.handleChange;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (formik?.values[name]) {
      formik.setFieldValue(name, formik?.values[name]?.trim());
    }
    //eslint-disable-next-line
  }, [formik?.values[name]]);

  return (
    <FormControl
      variant="outlined"
      size={size}
      fullWidth
      error={formik.touched[name] && Boolean(formik.errors[name])}
    >
      {/* Hidden field to prevent browser auto-fill */}
      <input type="text" style={{ display: "none" }} autoComplete="off" />

      <InputLabel
        sx={{
          color: formik.touched[name] && formik.errors[name] ? "red" : "",
        }}
      >
        {`${titleCase(name)} ${required ? "*" : ""}`}
      </InputLabel>
      <OutlinedInput
        type={showPassword ? "text" : "password"}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        name={name === "email" ? "user_email" : name} // Change email name to avoid auto-fill
        autoComplete={name === "email" ? "off" : "new-password"} // Disable auto-fill
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={`${titleCase(name)} ${required ? "*" : ""}`}
      />
      {formik.touched[name] && formik.errors[name] && (
        <Typography variant="caption" color="error" sx={{ my: 1 }}>
          {formik.errors[name]}
        </Typography>
      )}
    </FormControl>
  );
}
