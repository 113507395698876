import swal from "sweetalert2";

const showBasicSweetAlert = (icon = "success", message = "") => {
  swal.fire({
    text: message,
    icon: icon,
    showCloseButton: false,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'swal-confirm-button',
      cancelButton: 'swal-cancel-button'
    }
  });
}
const makeFirstLetterUpperCase = (string) => string.charAt(0).toUpperCase() + string.slice(1);
export const prompt = {
  success: (message) => showBasicSweetAlert("success", message),
  error: (message) => showBasicSweetAlert("error", message),
  warning: (message) => showBasicSweetAlert("warning", message),
  info: (message) => showBasicSweetAlert("info", message),
  formDataSuccess: async ({ action = "Create", item = "", navigate = () => { }, formik = {resetForm: () => {}}, loadRecord = () => { }, message = null, pkgActiveStatus=true }) => {
    const result = await swal.fire({
      text:!pkgActiveStatus ? message :`${makeFirstLetterUpperCase(item)} ${action}d successfully.`,
      icon:!pkgActiveStatus ? "warning": "success",
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Back to List",
      cancelButtonText:  "Okay",
      customClass: {
        confirmButton: 'swal-confirm-button',
        cancelButton: 'swal-cancel-button'
      }
    });
    if (result.isConfirmed) {
      navigate();
    } else {
      if (action === "create") {
        formik.resetForm();
      } else {
        loadRecord();
      }
    }
  },
  confirm: (message) => swal.fire({
    text: message,
    icon: "warning",
    showCloseButton: true,
    showConfirmButton: true,
    showCancelButton: true,
    customClass: {
      confirmButton: 'swal-confirm-button',
      cancelButton: 'swal-cancel-button'
    }
  }),
  withTimeAndEndDate: (price, endDate) => swal.fire({
    title: "Verify Package Details",
    html: `<div style="display: block; margin-bottom: 10px; text-align: left;">
                <label style="display: block; margin-bottom: 5px;">Price</label>
                <input type="number" id="price" value="${price}" placeholder="Enter price" class="swal2-input" style="width:80%">
                <label style="display: block;margin-top: 20px;">End Date</label>
                <input type="date" id="endAt" value="${endDate}" min="22-04-2024" placeholder="Enter end date" class="swal2-input" style="width:80%">
        </div>`,
    focusConfirm: false,
    preConfirm: () => {
      return {
        price: document.getElementById("price").value,
        endAt: document.getElementById("endAt").value
      }
    },
    allowOutsideClick: () => !swal.isLoading(),
    showCancelButton: true,
    confirmButtonText: "Activate",
    cancelButtonText: "Cancel",
    showLoaderOnConfirm: true,
    customClass: {
      confirmButton: 'swal-confirm-button',
      cancelButton: 'swal-cancel-button'
    }
  }),
  close: () => swal.close(),

};