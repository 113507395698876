import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search:{},
  pageId:""
};

const settingsSlice = createSlice({
  name: "search_slice",
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.search = action.payload.search;
      state.pageId = action.payload.pageId;
    },
  },
});

export const { updateSearch } = settingsSlice.actions;
export default settingsSlice.reducer;
