import { useState } from 'react';

import Slide from '@mui/material/Slide';
import Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { bgBlur } from 'src/theme/css';
import Iconify from 'src/components/iconify';
import { Box, Link, Tooltip } from '@mui/material';
import navConfig from "../config-navigation";


const removeNestedChildren = (navItems) => {
  const navChildren = [];
  const updatedNavConfig = navItems.reduce((acc, item) => {
    if (item.children) {
      item.children.forEach((child) => {
        navChildren.push(child);
      });
    } else {
      acc.push(item); // Keep items without children
    }
    return acc;
  }, []);

  return [...updatedNavConfig, ...navChildren];
};
const finalNavConfig = removeNestedChildren(navConfig);
function findNavItems( navItem = "") {
  const result = finalNavConfig.filter(navItem => navItem.havePermission).filter((item) => {
    return item.title.toLowerCase().includes(navItem.toLowerCase());
  });
  return result;
}

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({
    color: theme.palette.background.default,
  }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  position: 'absolute',
  alignItems: 'center',
  height:"min-content",
  padding: 4,
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height:"min-content",
    padding:4,
  },
}));



export default function Searchbar() {
  const [open, setOpen] = useState(false);
  const [search,setSearch] = useState("");
  const [navItems,setNavItems] = useState([]);
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (value) => {
    setSearch(value);
    if (value) {
      const result = findNavItems(value);
      setNavItems(result);
    }else{
      setNavItems([]);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <Tooltip title="Site navigation">
            <IconButton onClick={handleOpen} sx={{ color: "black" }}>
              <Iconify icon="eva:search-fill" />
            </IconButton>
          </Tooltip>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Box display={"flex"} p={2}>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search site navigation..."
                value={search}
                onChange={(event) => handleSearch(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: "text.disabled", width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
                sx={{ mr: 1, fontWeight: "fontWeightBold" }}
              />
            </Box>
            {navItems.length > 0 && (
              <Box p={2} display={"grid"}>
               
                {navItems.map((item, index) => {
                  return (
                    <Link
                      href={`${item.path}`}
                      fontWeight={"fontWeightBold"}
                      color={"primary.dark"}
                      key={index}
                    >
                      /{item.title}
                    </Link>
                  );
                })}
              </Box>
            )}
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
