import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";

import { bgGradient } from "src/theme/css";
import Logo from "src/components/logo";
import axios_api from "src/api/axios";
import { CustomTextInput } from "src/components/custom-text-input/custom-test-input";
import { CustomTextInputPassword } from "src/components/custom-text-input-password/custom-test-input-password";
import { useIsAuthenticated } from "src/routes/hooks/use-is-authenticated";
import { Link, useNavigate, useParams } from "react-router-dom";
import { removeForgotPasswordToken } from "src/redux/slice/forgot-password-slice";
import { setForgotPasswordToken } from "src/redux/slice/forgot-password-slice";
import { RouterLink } from "src/routes/components";
import { prompt } from "src/utils/prompt";
import changeCase from "case";
const START_TIME = 600; // Set to 10 minutes
const MAX_PASSWORD_ATTEMPTS = 3;

export default function ForgotPassword() {
  const theme = useTheme();
  const navigate = useNavigate();
  useIsAuthenticated();
  const [loading, setLoading] = useState(false);
  const forgotPasswordToken = localStorage.getItem("forgot-password-token");
  const [timeLeft, setTimeLeft] = useState(localStorage.getItem("password-reset-counter") ? Number(localStorage.getItem("password-reset-counter")) : START_TIME);
  
  const [passwordResendAttempts, setPasswordResendAttempts] = useState(0);
  const [passwordResendDisabled, setPasswordResendDisabled] = useState(false);
  const params = useParams();
  let timer;

  

  const startTimer = () => {
    clearInterval(timer);
    timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        localStorage.setItem("password-reset-counter", prevTime - 1);
        return prevTime - 1;
      });
    }, 1000);
  };

  useEffect(()=>{
  //  if(!forgotPasswordToken) return navigate("/login");
   // eslint-disable-next-line
  },[forgotPasswordToken]);

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      otp: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().trim()
        .required("OTP is required")
        .max(4, "OTP must be 4 characters")
        .min(4, "OTP must be 4 characters"),
      password: Yup.string().trim()
        .required("Password is required")
        .min(5, "Password must be at least 5 characters")
        .max(15, "Password must not exceed 15 characters"),
      confirm_password: Yup.string().trim()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      await axios_api.post(`/account/reset-password/${forgotPasswordToken}/`, values)
        .then(({data}) => {
          setLoading(false);
        
          if(data.result === "failure"){
            if(data.errors["instance"]){
              return prompt.error(changeCase.lower(data.errors["instance"]));
            }
            Object.keys(data.errors).forEach((err_key) => {
              formik.setFieldError(err_key, data.errors[err_key]);
            });
          } else {
            localStorage.removeItem("forgot-password-token");
            localStorage.removeItem("password-reset-counter");
            prompt.success("Password reset successfully completed");
            navigate("/login");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          prompt.error("Unable to reset password. Please try again");
          navigate("/forgot-password");
        });
    },
  });

  const resendPassword = async () => {
    try {
      setPasswordResendDisabled(true);
      if (passwordResendAttempts >= MAX_PASSWORD_ATTEMPTS) {
        prompt.error("Maximum password resend limit reached. Please try again after some time.");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        return;
      }

      if (timeLeft === 0) {
        setPasswordResendAttempts((prevAttempts) => prevAttempts + 1);
        const { data } = await axios_api.post(
          `account/recover-password-token/`,
          { email: params["email"] }
        );
        if (data["result"] === "success") {
          setTimeLeft(START_TIME);
          startTimer(); // Restart the timer
          localStorage.setItem("forgot-password-token", data["token"]);
          prompt.success("Password reset OTP sent successfully");
          setPasswordResendDisabled(false);
        }else if(data["result"] === "failure"){
          prompt.error("Something went wrong");
        } else {
          setPasswordResendDisabled(false);
          prompt.error(data["message"]);
        }
      }
    } catch (error) {
      prompt.error("Unable to resend password. Please try again later");
    }
  };

  const renderForm = (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <CustomTextInput
          formik={formik}
          name="otp"
          required
          size="large"
          label={"OTP"}
        />
        <CustomTextInputPassword
          name="password"
          size="large"
          formik={formik}
        />
        <CustomTextInputPassword
          name="confirm_password"
          size="large"
          formik={formik}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 3 }}
      >
        <Link
          component={RouterLink}
          variant="subtitle2"
          underline="hover"
          to="/login"
        >
          Back to login
        </Link>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} my={3}>
        { <Stack direction={"row"} alignItems={"center"}>
          <Typography>Resend in</Typography>
          <Typography variant="subtitle1" mx={1}>
            {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
          </Typography>
        </Stack>}
        <LoadingButton
          disabled={(timeLeft !== 0) || passwordResendDisabled}
          onClick={resendPassword}
          loading={passwordResendDisabled}
        >
          Resend OTP
        </LoadingButton>
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        loading={loading}
      >
        Reset Password
      </LoadingButton>
    </form>
  );
  
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_4.jpg",
        }),
        height: "100vh",
      }}
    >
      <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
            <img src="/assets/automarket-logo.png" alt="logo" height={30} />
          </Box>
          <Typography variant="h4" mb={5}>Enter OTP</Typography>

          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
}
