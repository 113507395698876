import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
// import Button from '@mui/material/Button';
import Avatar from "@mui/material/Avatar";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { usePathname } from "src/routes/hooks";
import { RouterLink } from "src/routes/components";

import { useResponsive } from "src/hooks/use-responsive";


// import Logo from 'src/components/logo';
import Scrollbar from "src/components/scrollbar";

import { NAV } from "./config-layout";
import navConfig from "./config-navigation";
import SvgColor from "src/components/svg-color";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { updateSearch } from "src/redux/slice/search-slice";
const setNavItem = (navString) => {
  if (navString) {
    localStorage.setItem("current_nav", navString);
  }
};
export default function Nav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const upLg = useResponsive("up", "lg");
  const { userInfo } = useSelector((state) => state.auth);
  const [openMenu, setOpenMenu] = useState({ open: false, name: "" });

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const handlePopState = () => {
      console.log('Browser back/forward button clicked');
      // Add your custom logic here
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);



  const renderAccount = (
    <Box >
      {!userInfo ? (
        <Box
          sx={{
            my: 3,
            mx: 2.5,
            py: 2,
            px: 2.5,
            display: "flex",
            borderRadius: 1.5,
            alignItems: "center",
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
          }}
        >
          {/* loader box */}
          <Skeleton variant="circular" width={40} height={40} />
          <Box sx={{ ml: 2, width: "70%" }}>
            <Skeleton variant="text" sx={{ fontSize: "1.2rem" }}></Skeleton>
            <Skeleton variant="text" sx={{ fontSize: ".8rem" }}></Skeleton>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            my: 3,
            mx: 2.5,
            py: 2,
            px: 2.5,
            display: "flex",
            borderRadius: 1.5,
            alignItems: "center",
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
          }}
        >
          {/* data box */}
          <Avatar src={userInfo?.image?.url} alt="photoURL" />

          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2">
              {userInfo.first_name ? `${userInfo.first_name} ${userInfo.last_name}` : <Skeleton variant="text" width={"80px"}/>}
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary", width:"150px", overflowWrap:"anywhere" }}>
              {userInfo.email ? userInfo.email : <Skeleton variant="text" width={"150px"}/>}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 ,paddingBottom:3}}>
      {navConfig.map((item) =>item.havePermission ?
        item.children ? (
          <ToggleNavItem
            key={item.title}
            {...item}
            children={item.children}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
           
          />
        ) : (
          <NavItem key={item.title} item={item} />
        ):null
      )}
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {/* <Logo sx={{ mt: 3, ml: 4 }} /> */}
      <Box
        component="img"
        sx={{ mt: 3, ml: 4}}
        width={180}
        alt="The house from the offer."
        src="/assets/automarket-logo.png"
      />
      {renderAccount}

      {renderMenu}

      <Box sx={{ flexGrow: 1}} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}



function NavItem({ item}) {
  const pathname = usePathname();
  const dispatch = useDispatch();
  let active = pathname.includes(item.path);
  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "text.secondary",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(item.path === localStorage.getItem("current_nav") && active  && {
          color: "primary.main",
          fontWeight: "fontWeightSemiBold",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
      onClick={()=>{
        setNavItem(item.path)
        dispatch(updateSearch({pageId:"",search:null}));
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
}

function ToggleNavItem({
  path,
  icon,
  title,
  children,
  openMenu,
  setOpenMenu,
}) {
  const dispatch = useDispatch();
  const pathname = usePathname();

  let parentActive = children.some(child=>{
    return (pathname.includes(`${child.path}details`)||
            pathname.includes(`${child.path}manage`) ||
            child.path.includes(pathname));
  });
  // parentActive = parentActive || (localStorage.getItem("current_nav") && localStorage.getItem("current_nav").includes(title));
  
  const [openCollapse, setCollapseOpen] = useState(parentActive);

  useEffect(() => {
    setCollapseOpen(parentActive);
  }, [parentActive]);

  return (
    <>
      <ListItemButton
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: "body2",
          color: "text.secondary",
          textTransform: "capitalize",
          fontWeight: "fontWeightMedium",
          ...(parentActive && {
            color: "primary.main",
            fontWeight: "fontWeightSemiBold",
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            "&:hover": {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
            },
          }),
        
        }}
        onClick={() => {
          setCollapseOpen((prev) => !prev);
        }}
      >
        <Stack
          direction="row"
          sx={{width:"100%",justifyContent:"space-between"}}
        >
          <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
            {icon}
          </Box>
          <Box sx={{display:"flex", justifyContent:"space-between", width:"100%"}}>
            <Typography variant="body2" >{title}</Typography>
            <Box sx={{ textAlign: "right" }}>
              {!openCollapse ? <ExpandMore /> : <ExpandLess />}
            </Box>
          </Box>
          
        </Stack>
      </ListItemButton>
      <Collapse in={openCollapse}>
        
        {children.length > 0 &&
          children.sort((a,b)=>a.title.localeCompare(b.title)).map((child, index) => (
            child.havePermission ?
            <ListItemButton
              key={index}
              component={RouterLink}
              sx={{
                pl: 4,
                borderRadius: 0.75,
                // backgroundColor:"green",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                color: "text.secondary",
                ...((child.path.includes(pathname) ||
                  pathname.includes(`${child.path}manage`) ||  pathname.includes(`${child.path}details`)) && {
                  color: "primary.main",
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                  "&:hover": {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                  },
                }),
              }}
              href={child.path}
              onClick={() => {
                setNavItem(child.path);
                dispatch(updateSearch({pageId:"",search:null}));
              }}
            >
              <Box
                component="span"
                sx={{ width: 14, height: 14, mr: 2, ml: 2 }}
              >
                {child.icon ? (
                  child.icon
                ) : (
                  <SvgColor
                    src={`/assets/icons/navbar/ic_star.svg`}
                    sx={{ width: 1, height: 1 }}
                  />
                )}
              </Box>
              <Box component="span" sx={{ fontSize: 14, pt: 1 }}>
                {child.title}
              </Box>
            </ListItemButton> : null
          ))}
      </Collapse>
    </>
  );
}
